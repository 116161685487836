<template>
  <a-modal
    :title="$t('warehouse.first_step') + ': ' +$t('account.verify_phone_number')"
    v-model:visible="state.visible"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    :auto-complete="false"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="state.formState"
      :rules="rules"
      :label-col="{ style: { width: '150px' } }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item :label="$t('account.cell_phone_number')">
        {{ state.formState.phoneCountryCode || '' }} {{ state.formState.phoneNumber }}
      </a-form-item>
      <a-form-item :label="$t('account.validation_code')" name="validCode">
        <a-input v-model:value="state.formState.validCode" :maxlength="8">
          <template #suffix>
            <a-button
              size="small"
              type="link"
              :loading="state.codeLoading"
              :disabled="state.countdown !== state.defaultCountdown"
              @click="handleSendCode()"
            >
            {{ state.countdown === state.defaultCountdown ? $t('user.send_sms_verification_code') : state.countdown + 's' }}
            </a-button>
          </template>
        </a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, watch } from 'vue';
import { Button, Form, Input, Modal, message } from 'ant-design-vue';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import {
  getUpdateUserMailSmsValidCode,
  checkUpdateUserMailSmsValidCode,
} from '@/api/modules/setting/notice.js';

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AButton: Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);

    const state = reactive({
      visible: false,
      codeLoading: false,
      confirmLoading: false,
      formState: {
        phoneCountryCode: null,
        phoneNumber: null,
        validCode: null,
      },
      countdown: 60,
      defaultCountdown: 60,
      countdownTimer: null,
    })

    const rules = {
      validCode: [
        { required: true, message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('account.validation_code')]) },
      ]
    }

    const handleSetCountdown = () => {
      if (state.countdown === 1 || !state.visible) {
        clearTimeout(state.countdownTimer);
        state.countdown = state.defaultCountdown;
        return;
      }
      --state.countdown;
      state.countdownTimer = setTimeout(handleSetCountdown, 1000);
    }

    const handleSendCode = async () => {
      try {
        state.codeLoading = true;
        await getUpdateUserMailSmsValidCode();
        message.success(vueI18n.t('user.sms_sent_successfully'));
        handleSetCountdown();
      } catch (error) {
      } finally {
        state.codeLoading = false;
      }
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        let { result } = await checkUpdateUserMailSmsValidCode({ validCode: state.formState.validCode });
        if (result && result?.isSuccess) {
          emit('confirm', result?.checkCode ?? '');
          close();
        } else {
          message.error(vueI18n.t('common.failed'));
        }
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
    }

    watch(() => props.visible, (visible) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          nextTick(() => {
            formRef.value.resetFields();
            state.formState.phoneCountryCode = getters.userInfo.phoneCountryCode;
            state.formState.phoneNumber = getters.userInfo.phoneNumber;
          });
        }
      }
    }, { immediate: true })

    return {
      state,
      rules,
      formRef,
      handleSendCode,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>